import * as React from "react";

import {
  Stack,
  Box,
  Text,
  Image,
  chakra,
  Button,
  Link,
  Input,
  Textarea,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import { EmailIcon, SunIcon } from "@chakra-ui/icons";

import { motion, aniamte } from "framer-motion";

import "./styles.css";

import Human from "../images/human-animated.svg";

const MotionStack = motion(Stack);
const MotionBox = motion(Box);

const filters = {
  oldPhoto:
    "blur(0.2px) brightness(1.1) hue-rotate(5deg) opacity(0.9) saturate(1.3) sepia(0.4)",
  horror: "grayscale(1) sepia(0.5) brightness(1.3) invert(0.8)",
  fadeOut:
    "brightness(0.8) hue-rotate(350deg) saturate(3) blur(2px) contrast(0.6)",
  custom: "brightness(0.9) contrast(1.2) hue-rotate(-20deg) saturate(2) ",
  custom1: "saturate(0.9) brightness(0.9)",
};

const colors = {
  blue: "#142664",
  green: "#00E6B3",
  red: "#FF2E93",
};

// filter={`drop-shadow(0px 0px 3px ${colors.red})`}

const IndexPage = () => {
  return (
    <chakra.main minH="100vh" pb={24} fontFamily="'Poppins', sans-serif">
      <title>Alya's Page</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap&family=Poppins"
        rel="stylesheet"
      />

      <Stack
        bgImage="/assets/noise-transparent.png"
        minH="100vh"
        align="center"
        pt={0}
      >
        <Box className="top-border" w="100%" />
        <Stack spacing={[10, 12]} pb="30vh">
          <MotionStack
            direction="row"
            spacing="0"
            width="10em"
            justify="flex-start"
            whileHover={{
              scale: 1.6,
              translateX: "3em",
              translateZ: "2em",
              translateY: "0.5em",
            }}
            _hover={{ filter: "hue-rotate(30deg) drop-shadow(2px 2px red)" }}
          >
            <Image src={"/assets/at.svg"} w="20%" h="2em" />
            <Image src={"/assets/a.svg"} w="20%" h="2em" />
            <Image src={"/assets/l.svg"} w="20%" h="2em" />
            <Image src={"/assets/y.svg"} w="20%" h="2em" />
            <Image src={"/assets/a.svg"} w="20%" h="2em" />
          </MotionStack>

          <Stack direction="column"></Stack>

          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "center" }}
            align={{ base: "center", md: "center" }}
            w="6xl"
            maxW="95vw"
            h="inherit"
            zIndex="10"
            spacing={{ base: "-20%", md: 2 }}
          >
            <Stack
              w={{ base: "90%", md: "40%" }}
              minH="300px"
              p={4}
              pb={16}
              align="center"
              justify="center"
              textAlign="center"
              fontSize={["sm", "lg"]}
              className="bubble"
              _hover={{ filter: "drop-shadow(2px 2px purple)" }}
            >
              <Text
                fontFamily="'Press Start 2P', cursive"
                fontSize={["2xl", "4xl"]}
                className="underlined underlined-offset"
              >
                {""}Hello!
              </Text>

              <Text color="white">
                <a className="underlined underlined-thin">
                  I am back in{" "}
                  <Link
                    href="https://www.cam.ac.uk/"
                    target="_blank"
                    className="underlined underlined-thick"
                    textDecoration="none"
                  >
                    school
                  </Link>{" "}
                  to get a Ph.D. and move into{" "}
                  <Link
                    href="https://en.wikipedia.org/wiki/Topological_insulator"
                    target="_blank"
                    className="underlined underlined-thick"
                    textDecoration="none"
                  >
                    research.
                  </Link>
                </a>
              </Text>
              <Text>
                <a className="underlined underlined-thin">
                  I will not be available for at least the next three years.
                </a>
              </Text>
              <Text color={colors.green}>◈</Text>
              <Text>
                <a className="underlined underlined-thin">
                  This website now displays a collection of fun interactions and
                  animations I created while exploring tech options for a more
                  serious project.
                </a>
              </Text>
            </Stack>
            <MotionBox
              w={{ base: "70%", md: "60%" }}
              maxH="90vh"
              _hover={{
                filter: "drop-shadow(2px 2px purple);",
              }}
              filter="drop-shadow(1px 1px 1px purple) "
              cursor="grabbing"
              zIndex="1"
              drag
              dragConstraints={{
                top: -50,
                left: -50,
                right: 50,
                bottom: 50,
              }}
            >
              <Human />
            </MotionBox>
          </Stack>

          <Stack
            direction={{ base: "column-reverse", md: "row" }}
            justify={{ base: "center", md: "start" }}
            align={{ base: "center", md: "center" }}
            w="6xl"
            maxW="95vw"
            h="inherit"
            zIndex="10"
            spacing={{ base: -20, md: 8 }}
          >
            <MotionBox
              w={{ base: "45%", md: "30%" }}
              maxH="90vh"
              cursor="grabbing"
              drag
              dragConstraints={{
                top: -50,
                left: -50,
                right: 50,
                bottom: 50,
              }}
              zIndex="1"
              _hover={{ filter: "drop-shadow(2px 2px purple);" }}
              alignSelf="flex-start"
              filter="drop-shadow(1px 1px 1px purple) "
            >
              <Image src={"/assets/robot-animated2.svg"} />
            </MotionBox>

            <Stack
              w={{ base: "90%", md: "70%" }}
              p={4}
              pb={8}
              align="center"
              justify="center"
              textAlign="center"
              fontSize={["sm", "lg"]}
              color="white"
              className="robot-bubble"
            >
              <Text
                fontFamily="'Press Start 2P', cursive"
                fontSize={["2xl", "4xl"]}
                className="underlined underlined-offset"
              >
                Contact
              </Text>
              <Text>
                If you don't know my email address already, use this form.
              </Text>
              <chakra.form
                action="https://getform.io/f/514b2eca-6613-490f-8eec-9f79c3292271"
                method="POST"
                w="100%"
              >
                <Stack py={2} px={8} spacing={4}>
                  <Stack direction={["column", "column", "row"]}>
                    <FormControl id="name" isRequired>
                      <FormLabel fontSize={["sm", "lg"]}>Your name</FormLabel>
                      <Input type="text" name="text" />
                    </FormControl>
                    <FormControl id="email">
                      <FormLabel fontSize={["sm", "lg"]}>
                        Email address
                      </FormLabel>
                      <Input type="email" name="email" />
                    </FormControl>
                  </Stack>

                  <FormControl id="message" isRequired>
                    <FormLabel fontSize={["sm", "lg"]}>Message</FormLabel>
                    <Textarea type="text" name="message" resize="vertical" />
                  </FormControl>

                  <Button
                    leftIcon={<EmailIcon />}
                    className="gradient-border"
                    variant="outline"
                    colorScheme="white"
                    size="md"
                    alignSelf="flex-end"
                    type="submit"
                  >
                    Send
                  </Button>
                </Stack>
              </chakra.form>
            </Stack>
          </Stack>
        </Stack>

        <Box
          position="absolute"
          h="inherit"
          top="0"
          left="0"
          zIndex="2"
          w="100%"
          // filter={filters.custom1}
        >
          <MotionBox
            bgImage={"url('/assets/spaceship.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="contain"
            position="absolute"
            top="5vh"
            left="80vw"
            h="15vh"
            w="15vw"
            animate={{
              opacity: [0, 0.8, 1, 0.8, 0],
              scale: [0.1, 0.9, 1, 0.9, 0.1],
              type: "spring",
              filter: [
                "brightness(2)",
                "brightness(1)",
                "brightness(1)",
                "brightness(2)",
                "brightness(1)",
              ],
              x: ["0vw", "-10vw", "0vw", "-60vw", "-5vw"],
              y: ["-5vh", "0vh", "8vh", "3vh", "-2vh"],
              transition: {
                ease: "easeInOut",
                times: [0, 0.1, 0.5, 0.8, 1],
                duration: 10,
                repeat: Infinity,
                repeatDelay: 30,
              },
            }}
          />
        </Box>

        <Box
          position="absolute"
          h="inherit"
          bottom="0"
          left="0"
          zIndex="1"
          // filter={filters.custom1}
        >
          <MotionBox
            bgImage={"url('/assets/planet3.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="contain"
            position="absolute"
            bottom="35vh"
            left="0vw"
            h="20vh"
            w="20vw"
            animate={{
              scale: [1.1, 0.6, 1],
              filter: [
                "hue-rotate(40deg)",
                "hue-rotate(30deg)",
                "hue-rotate(60deg)",
              ],

              y: ["20vh", "-10vh", "30vh"],
              transition: {
                delay: 20,
                ease: "easeInOut",
                times: [0, 0.5, 1],
                duration: 35,
                repeat: Infinity,
                repeatDelay: 50,
              },
              translateX: "75vw",
            }}
          />
          <MotionBox
            bgImage={"url('/assets/planet2.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="contain"
            position="absolute"
            bottom="35vh"
            left="0vw"
            h="10vh"
            w="10vw"
            animate={{
              scale: [1, 0.8, 1],
              filter: [
                "hue-rotate(10deg)",
                "hue-rotate(30deg)",
                "hue-rotate(20deg)",
              ],

              y: ["15vh", "-5vh", "20vh"],
              transition: {
                ease: "easeInOut",
                times: [0, 0.5, 1],
                duration: 15,
                repeat: Infinity,
                repeatDelay: 30,
              },
              translateX: "70vw",
            }}
          />
        </Box>

        <Box
          position="absolute"
          h="inherit"
          bottom="0"
          left="0"
          zIndex="1"
          w="100%"
          filter={filters.custom1}
        >
          <Box
            bgImage={"url('/assets/waves/5.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="absolute"
            bottom="0"
            h="40vh"
            w="100%"
          >
            <Box
              position="absolute"
              bottom="65%"
              left="0%"
              w={{ base: "60%", md: "30%", xl: "20%" }}
              h={{ base: "35%", md: "40%", xl: "50%" }}
            >
              <Box
                bgImage={"url('/assets/house.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="35%"
                left="0%"
                h="45%"
                w="35%"
              />
              <Box
                bgImage={"url('/assets/house2.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="35%"
                left="15%"
                h="65%"
                w="35%"
              />
              <Box
                bgImage={"url('/assets/house1.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="35%"
                left="30%"
                h="35%"
                w="35%"
              />
              <Box
                bgImage={"url('/assets/car.svg')"}
                bgPos="left"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="0%"
                left="2%"
                h="30%"
                w="30%"
              />
            </Box>
          </Box>

          <Box
            bgImage={"url('/assets/waves/4.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="absolute"
            bottom="0"
            h="45vh"
            w="100%"
          >
            <Box
              bgImage={"url('/assets/rig.svg')"}
              bgPos="top"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="45%"
              right="1%"
              h="25%"
              w="15%"
            />
          </Box>
          <Box
            bgImage={"url('/assets/waves/3.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="absolute"
            bottom="0"
            h="25vh"
            w="100%"
          >
            <Box
              bgImage={"url('/assets/house3.svg')"}
              bgPos="top"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="55%"
              right="30%"
              h="35%"
              w="35%"
            />
            <Box
              bgImage={"url('/assets/egg.svg')"}
              bgPos="top"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="50%"
              right="50%"
              h="12%"
              w="12%"
            />
            <Box
              bgImage={"url('/assets/platform1.svg')"}
              bgPos="top"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="50%"
              right="36%"
              h="12%"
              w="15%"
            />
            <Box
              bgImage={"url('/assets/plants1.svg')"}
              bgPos="left"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="30%"
              left="5%"
              h="55%"
              w="40%"
              filter={`drop-shadow(0px 0px 3px ${colors.blue})`}
            />
          </Box>

          <Box
            bgImage={"url('/assets/waves/2.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="absolute"
            bottom="0"
            h="20vh"
            w="100%"
          ></Box>

          <Box
            bgImage={"url('/assets/waves/1.svg')"}
            bgPos="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="absolute"
            bottom="0"
            h="15vh"
            w="100%"
          >
            <Box
              bgImage={"url('/assets/tree1.svg')"}
              bgPos="bottom"
              bgRepeat="no-repeat"
              bgSize="contain"
              position="absolute"
              bottom="20%"
              right="8%"
              h="160%"
              w="40%"
              filter={`drop-shadow(0px 0px 4px ${colors.blue})`}
            >
              <Box
                bgImage={"url('/assets/mushroom1.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="0%"
                right="0%"
                h="12%"
                w="80%"
              />
              <Box
                bgImage={"url('/assets/mushroom1.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="0%"
                right="0%"
                h="10%"
                w="84%"
              />
              <Box
                bgImage={"url('/assets/mushroom2.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="0%"
                right="0%"
                h="8%"
                w="78%"
              />
              <MotionBox
                bgImage={"url('/assets/plants2.svg')"}
                bgPos="bottom"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="0%"
                left="5%"
                h="40%"
                w="90%"
                filter={`drop-shadow(0px 0px 3px ${colors.blue})`}
                whileHover={{
                  rotate: ["0deg", "-5deg", "5deg", "0deg"],
                  transition: {
                    duration: 2,
                    times: [0, 0.3, 0.6, 1],
                    repeate: Infinity,
                  },
                }}
              />
            </Box>
            <Box w="90%" zIndex="2">
              <MotionBox
                bgImage={"url('/assets/alien.svg')"}
                bgPos="left"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="25%"
                left="0%"
                h="55%"
                w="15%"
                _hover={{
                  filter: "hue-rotate(20deg) drop-shadow(0px 0px 80px  #fff)",
                }}
                animate={{
                  opacity: [0, 1, 0],
                  transition: {
                    times: [0, 0.5, 1],
                    duration: 30,
                    repeat: Infinity,
                    repeatDelay: 90,
                  },
                  translateX: "75vw",
                  translateY: "5%",
                }}
                whileHover={{
                  y: ["0%", "-20%", "0%"],
                  transition: {
                    duration: 2,
                    times: [0, 0.5, 1],
                    repeate: Infinity,
                  },
                }}
              />
              <MotionBox
                bgImage={"url('/assets/alien-flip.svg')"}
                bgPos="right"
                bgRepeat="no-repeat"
                bgSize="contain"
                position="absolute"
                bottom="20%"
                right="25%"
                h="55%"
                w="15%"
                opacity={0}
                _hover={{
                  filter: "hue-rotate(20deg) drop-shadow(0px 0px 80px  #fff)",
                }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: [0, 1, 0],
                  transition: {
                    times: [0, 0.5, 1],
                    delay: 45,
                    duration: 30,
                    repeat: Infinity,
                    repeatDelay: 90,
                  },
                  translateX: "-65vw",
                  translateY: "-5%",
                }}
                whileHover={{
                  y: ["0%", "-20%", "0%"],
                  transition: {
                    duration: 2,
                    times: [0, 0.5, 1],
                    repeate: Infinity,
                  },
                }}
              />
            </Box>
          </Box>

          <Text
            position="absolute"
            bottom="5%"
            right="10%"
            color="gray.100"
            fontSize={["xs", "sm", "md"]}
          >
            {"✨ "}I made the animated svgs so feel free to steal them
          </Text>
        </Box>
      </Stack>
    </chakra.main>
  );
};

export default IndexPage;
